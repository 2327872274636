<template>
  <div id="negociation-immobilier"></div>
  <div class="container__real--estate">
    <h2>
      {{
        store.languageSwitcher == 0
          ? "Négociation immobilière"
          : store.languageSwitcher == 1
          ? "Real Estate Negotiation"
          : "Negociación inmobiliaria"
      }}
    </h2>

    <div class="container__real--estate--rule"></div>

    <div class="container__real--estate--content">
      <p>
        {{
          store.languageSwitcher == 0
            ? "Vous souhaitez vendre ou acheter un bien immobilier ?"
            : store.languageSwitcher == 1
            ? "Are you looking to buy or sell a property?"
            : "¿Quiere comprar o vender un inmueble? "
        }}
      </p>
      <p class="" data-fade-speed="600" >
        {{
          store.languageSwitcher == 0
            ? "Commercialisez votre bien. Nous trouvons des acheteurs, réalisons les diagnostics,\
              rédigeons et diffusons les annonces, organisons\
              les visites et sélectionnons les candidats acheteurs jusqu'à la promesse de vente"
            : store.languageSwitcher == 1
            ? "Find a buyer by marketing your property. We'll carry out the necessary technical diagnostics,\
              write and circulate the adverts, organise viewings,\
              select prospective buyers right through to the promise to sell,\
              and provide you with a single point of contact to simplify your experience!"
            : "Encuentre un comprador comercializando su inmueble. Realizamos los diagnósticos técnicos necesarios,\
              redactamos y difundimos los anuncios, organizamos las visitas,\
              seleccionamos a los posibles compradores hasta la promesa de venta,\
              y ponemos a su disposición un interlocutor único para simplificarle\
              la experiencia."
        }}
      </p>
      <p>
        <a href="http://ducamp-monod.site.azko.fr/accueil.htm">{{
          store.languageSwitcher == 0
            ? "Consultez nos annonces"
            : store.languageSwitcher == 1
            ? "Browse our ads"
            : "Ver nuestros anuncios"
        }}</a>
      </p>
    </div>
  </div>
</template>

<script setup>
import { useLanguage } from "@/config/stores";
const store = useLanguage();
</script>

<style></style>
