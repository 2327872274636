<template>
  <NavBar />
  
  <header
    id="homeHeader"
    class="home--header"
    style="width: 100%; height: 100vh"
  >
    <img
      :src="require('@/assets/entry.webp')"
      alt="L'agence Ducamp-Monod"
      id="homeBanner"
      style="width: 100%"
    />
    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 20010904//EN" "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
    <LogoMain class="home--header__logo" id="headerLogo" />
  </header>

  <!-- <NavBar /> -->

  <div class="container">
    <img
      :src="require('@/assets/st-augustin.webp')"
      alt="Saint Augustin"
      loading="lazy"
      class="bck--img"
    />
    <Office />
    <RealEstateBanner />
    <Expertise />
    <Labels />
    <ContactUs />
    <Values />
    <Btn />
  </div>
</template>

<script setup>
import { onMounted } from "vue";
// import { axios, API } from "@/config/config";

import NavBar from "@/components/NavBar.vue";
import Office from "@/components/TheOffice.vue";
import Expertise from "@/components/OurExpertise.vue";
import RealEstateBanner from "@/components/RealEstateBanner.vue";
import Labels from "@/components/OurLabels.vue";
import Values from "@/components/OurValues.vue";
import ContactUs from "@/components/ContactUs.vue";
import LogoMain from "@/components/LogoMain.vue";
import Btn from "@/components/RDVBtn.vue";

onMounted(async () => {
  let logo = document.getElementById("headerLogo");
  let header = document.getElementById("homeHeader");
  //   let img = document.getElementById("homeBanner");
  let height = header.offsetHeight;

  (window.onscroll = async function () {
    let scroll =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    logo.style.transform =
      -scroll >= -300 ? `translateY(${-scroll}px)` : `translateY(${300}px)`;
    logo.style.opacity = Math.max(0, Math.min(1, -scroll / 100 + 2));
    Math.floor(height - scroll) >= 450
      ? (header.style.height = `${Math.floor(height - scroll)}px`)
      : (header.style.height = `450px`);
  }),
    { passive: true };

  // await axios
  //   .get(`${API}/ping`)
  //   .then(async (res) => {
  //     if (res.status == 204) null;
  //   })
  //   .catch((e) => {
  //     if (e) console.error("error" + e);
  //   });
}),
  { passive: true };
</script>

<style lang="scss">
.home--header {
  display: flex;
  justify-content: center;
  align-items: center;
  filter: brightness(125%);
  position: relative;

  img {
    margin-top: -2px;
    height: 99.8%;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    object-fit: cover;
  }

  &__logo {
    max-width: 80%;
    margin: 0px 10px;
    position: absolute;
  }
}
</style>
