<template>
    <div class="container__expertise">

        <span class="tr--top-wrap">
            <div class="container__expertise__tr__top"></div>
        </span>

        <div class="container__expertise__holder">
            <h2>{{
                    (store.languageSwitcher == 0) ? "Notre expertise" : (store.languageSwitcher == 1) ? "Our expertise" :
                        "Nuestra experiencia"
            }}</h2>

            <div class="container__expertise__list">
                <button
                    v-for="item, i in (store.languageSwitcher == 0) ? expertise_fr : (store.languageSwitcher == 1) ? expertise_en : expertise_sp"
                    :key="i" @click="swapper(i)" :class="(pole_id == i) ? 'expertise-selected' : null">
                    {{ item.pole }}
                </button>
            </div>

            <div class="container__expertise__p">
                <p id="ourExpertise">{{ (store.languageSwitcher == 0) ? expertise_fr[pole_id].text : (store.languageSwitcher == 1) ?
                        expertise_en[pole_id].text : expertise_sp[pole_id].text
                }}</p>
            </div>

            <div class="container__expertise__btn">
                <a :href="`${URI}/metiers`" class="lnk-btn">{{ (store.languageSwitcher == 0) ? "Voir les metiers" :
                        (store.languageSwitcher == 1) ? "See the jobs" : "Ver los empleos"
                }}</a>
            </div>
        </div>

        <span class="tr--bottom-wrap">
            <div class="container__expertise__tr__bottom"></div>
        </span>

    </div>
</template>
  
<script setup>
import { ref, onBeforeUnmount, onMounted, onBeforeMount } from 'vue';
import { URI } from '@/config/config';
import { useLanguage } from '@/config/stores';

const [store, expertise, timer, pole_id] = [useLanguage(), ref(), ref(), ref()];

let expertise_fr = ref([
    { pole: 'international', text: "Fort de plusieurs expériences en cabinets étrangers, et maîtrisant plusieurs langues, les notaires et collaborateurs de l’Étude assistent aussi bien les étrangers que les Français expatriés investissant en France ou y ayant des affaires." },
    { pole: 'immobilier', text: "Ce pôle est en charge des opérations foncières pour le compte de particuliers, de professionnels, de foncières ou de sociétés d’investissement." },
    { pole: 'patrimoine', text: "Lors d’un bilan patrimonial, le notaire vous propose différentes façons de transmettre votre patrimoine : soit directement, par donation, donation-partage, ou autres. Soit indirectement, grâce à une société familiale qu’il vous aura aidé à constituer." },
    { pole: 'famille', text: "En qualités de conseiller des familles, le notaire est au premier plan pour accompagner les clients à chaque étape importante de leur vie. Il garde mémoire des actes et de la vie des familles." },
    { pole: 'sociétés', text: "L’office accompagne le chef d’entreprise tout au long de son parcours. En phase de lancement, l’office propose son expertise pour structurer au mieux, d’un point de vue juridique, fiscal et social, le projet entrepreneurial." }
]);
let expertise_en = ref([
    { pole: 'international operations', text: "With several experiences in foreign firms, and mastering several languages, the notaries and collaborators of the firm assist both foreigners and French expatriates investing in France or having business there." },
    { pole: 'real Estate ', text: "This division is in charge of real estate transactions on behalf of individuals, professionals, real estate companies or investment companies." },
    { pole: 'asset management', text: "During a heritage assessment, the notary offers you different ways of transmitting your heritage: either directly, by donation, donation-sharing, or others. Or indirectly, thanks to a family company that he will have helped you to set up." },
    { pole: 'family', text: "As a family adviser, the notary is at the forefront to support clients at each important stage of their lives. It keeps memory of the acts and the life of the families." },
    { pole: 'firms', text: "The office accompanies the entrepreneur throughout his career. In the launch phase, the office offers its expertise to best structure, from a legal, fiscal and social perspective, the entrepreneurial project." }
]);
let expertise_sp = ref([
    { pole: 'internacional', text: "Con varias experiencias en firmas extranjeras y dominando varios idiomas, los notarios y colaboradores de la firma asisten tanto a los extranjeros como a los expatriados franceses que invierten en Francia o tienen negocios allí." },
    { pole: 'inmobiliaria ', text: "Esta división se encarga de las transacciones inmobiliarias por cuenta de particulares, profesionales, sociedades inmobiliarias o sociedades de inversión." },
    { pole: 'patrimonio', text: "Durante una valoración patrimonial, el notario le ofrece diferentes formas de transmitir su patrimonio: ya sea directamente, por donación, donación-participación u otras. O indirectamente, gracias a una empresa familiar que él te habrá ayudado a montar." },
    { pole: 'familia', text: "Como asesor familiar, el notario está a la vanguardia para apoyar a los clientes en cada etapa importante de sus vidas. Guarda memoria de los actos y de la vida de las familias." },
    { pole: 'empressas', text: "La oficina acompaña al emprendedor a lo largo de su carrera. En la fase de lanzamiento, la oficina ofrece su experiencia para estructurar mejor, desde el punto de vista legal, fiscal y social, el proyecto empresarial." }
]);

onBeforeMount(async () => {
    pole_id.value = 0;
    sessionStorage.setItem('pole', 0);
});
onMounted(async () => {
    expertise.value = document.getElementById('ourExpertise');
    timer.value = setInterval(() => {
        expertiseSwap(pole_id.value + 1);
    }, 10000);
});
onBeforeUnmount(async () => {
    clearInterval(timer.value);
});

function swapper(_id) {
    clearInterval(timer.value);
    expertiseSwap(_id);
    timer.value = setInterval(() => {
        expertiseSwap(pole_id.value + 1);
    }, 10000);
}

async function expertiseSwap(_id) {
    expertise.value.classList.toggle("fade-out");
    (_id > 4) ? _id = 0 : null;
    sessionStorage.setItem('pole', _id);
        
    await new Promise(r => setTimeout(r, 1000));
    pole_id.value = _id;

    await new Promise(r => setTimeout(r, 1000));
    expertise.value.classList.toggle("fade-out");
}
</script>
  
<style  >

</style>
