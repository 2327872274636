<template>
    <b id="appointment"></b>
    <div class="container__contact">
        
        <h2>{{ (store.languageSwitcher == 0) ? "Contactez\nNous" : (store.languageSwitcher == 1) ? "Contact Us" : "Contáctenos" }}</h2>
        
        <div class="container__contact--form">
            <ContactForm />
        </div>

        <div class="container__contact--card">
            <ContactCard />
        </div>

    </div>
</template>
  
<script setup>
import ContactCard from '@/components/ContactCard.vue';
import ContactForm from '@/components/ContactForm.vue';
import { useLanguage } from '@/config/stores';

const store = useLanguage();
</script>
  
<style>

</style>