<template>
  <div class="container__values">
    <h2>
      {{
        store.languageSwitcher == 0
          ? "Nos Valeurs"
          : store.languageSwitcher == 1
          ? "Our Values"
          : "Nuestros valores"
      }}
    </h2>

    <Carousel
      class="carousel"
      :items-to-show="sWidth < 768 ? 1.2 : 1.7"
      :wrap-around="true"
    >
      <Slide class="carousel__slides">
        <div
          class="carousel__item carousel__item__holder"
          style="width: 100%; height: 60vh"
        >
          <img
            :src="require('@/assets/metiers.webp')"
            :alt="
              store.languageSwitcher == 0
                ? 'Securite'
                : store.languageSwitcher == 1
                ? 'Security'
                : 'Seguridad'
            "
            id="scrt"
            style="height: 60vh"
          />
          <div class="carousel__item__cnt">
            <span v-if="store.languageSwitcher == 0">
              <h3>Securite</h3>
              <p>
                Le notaire veille à l'équilibre du contrat, et s'assure qu’il
                soit signé et exécuté selon la loi des parties. Son objectif est
                de prévenir les litiges, source de coûts et frais de procédure,
                de tracas et de perte de temps.
              </p>
            </span>
            <span v-if="store.languageSwitcher == 1">
              <h3>Security</h3>
              <p>
                The notary ensures that the contract is balanced and that it is
                signed and executed according to the law of the parties. His aim
                is to prevent disputes, which are a source of costs and
                procedural fees, hassle and waste of time.
              </p>
            </span>
            <span v-if="store.languageSwitcher == 2">
              <h3>Seguridad</h3>
              <p>
                El notario se asegura de que el contrato sea equilibrado y de
                que se firme y ejecute de acuerdo con la ley de las partes. Su
                objetivo es evitar los litigios, que son fuente de costes y
                tasas procesales, molestias y pérdidas de tiempo.
              </p>
            </span>
          </div>
        </div>
      </Slide>
      <Slide class="carousel__slides">
        <div class="carousel__item carousel__item__holder">
          <img
            :src="require('@/assets/office.webp')"
            :alt="
              store.languageSwitcher == 0
                ? 'Qualite & competence'
                : store.languageSwitcher == 1
                ? 'Quality competence'
                : 'Calidad competencia'
            "
            id="qc"
            style="height: 60vh"
          />
          <div class="carousel__item__cnt">
            <span v-if="store.languageSwitcher == 0">
              <h3>
                Qualite <br />
                competence
              </h3>
              <p>
                La maîtrise du droit et de sa technicité nous permet d’être
                imaginatifs et de vous proposer des solutions sur mesure. Notre
                équipe est dynamique, disponible et efficace.
              </p>
            </span>
            <span v-if="store.languageSwitcher == 1">
              <h3>
                Quality <br />
                competence
              </h3>
              <p>
                Our mastery of the law and its technicalities allows us to be
                imaginative and to offer you tailor-made solutions. Our team is
                dynamic, available and efficient.
              </p>
            </span>
            <span v-if="store.languageSwitcher == 2">
              <h3>
                Calidad <br />
                competencia
              </h3>
              <p>
                Nuestro dominio del derecho y de sus tecnicismos nos permite ser
                imaginativos y ofrecerle soluciones a medida. Nuestro equipo es
                dinámico, disponible y eficaz.
              </p>
            </span>
          </div>
        </div>
      </Slide>
      <Slide class="carousel__slides">
        <div class="carousel__item carousel__item__holder">
          <img
            :src="require('@/assets/tingey-injury-law-firm.webp')"
            :alt="
              store.languageSwitcher == 0
                ? 'Probite'
                : store.languageSwitcher == 1
                ? 'Probity'
                : 'Probidad'
            "
            id="prbt"
            style="height: 60vh"
          />
          <div class="carousel__item__cnt">
            <span v-if="store.languageSwitcher == 0">
              <h3>Probite</h3>
              <p>
                La minutie et l'intégrité du notaire dans l'accomplissement de
                sa mission en veillant au strict respect des règles légales et
                déontologiques témoignent de sa volonté d'offrir un véritable
                service à valeur ajoutée.
              </p>
            </span>
            <span v-if="store.languageSwitcher == 1">
              <h3>Probity</h3>
              <p>
                The notary's meticulousness and integrity in carrying out his
                mission while ensuring strict compliance with legal and ethical
                rules testify to his desire to offer a genuine value-added
                service.
              </p>
            </span>
            <span v-if="store.languageSwitcher == 2">
              <h3>Probidad</h3>
              <p>
                La meticulosidad y la integridad del notario en el desempeño de
                su misión, velando al mismo tiempo por el estricto cumplimiento
                de las normas jurídicas y deontológicas, dan fe de su voluntad
                de ofrecer un auténtico servicio de valor añadido.
              </p>
            </span>
          </div>
        </div>
      </Slide>
      <Slide class="carousel__slides">
        <div class="carousel__item carousel__item__holder">
          <img
            :src="require('@/assets/entrance.webp')"
            :alt="
              store.languageSwitcher == 0
                ? 'Proximite'
                : store.languageSwitcher == 1
                ? 'Proximity'
                : 'Proximidad'
            "
            style="height: 60vh"
          />
          <div class="carousel__item__cnt">
            <span v-if="store.languageSwitcher == 0">
              <h3>Proximite</h3>
              <p>
                Chaque client est unique, chaque solution est spécifique. Votre
                présence est essentielle afin de mieux cerner vos attentes, vos
                contraintes et vos besoins, pour pouvoir vous proposer les
                meilleures solutions au regard de vos objectifs.
              </p>
            </span>
            <span v-if="store.languageSwitcher == 1">
              <h3>Proximity</h3>
              <p>
                Each client is unique, each solution is specific. It is
                essential that we understand your expectations and needs so that
                we can offer you the right solutions to meet your objectives.
              </p>
            </span>
            <span v-if="store.languageSwitcher == 2">
              <h3>Proximidad</h3>
              <p>
                Cada cliente es único, cada solución es específica. Su presencia
                es esencial para comprender mejor sus expectativas, sus
                limitaciones y sus necesidades, de modo que podamos ofrecerle
                las mejores soluciones para alcanzar sus objetivos.
              </p>
            </span>
          </div>
        </div>
      </Slide>
      <Slide class="carousel__slides">
        <div class="carousel__item carousel__item__holder">
          <img
            :src="require('@/assets/roof.webp')"
            :alt="
              store.languageSwitcher == 0
                ? 'Environnement'
                : store.languageSwitcher == 1
                ? 'Environment'
                : 'Medio ambiente'
            "
            style="height: 60vh"
          />
          <div class="carousel__item__cnt">
            <span v-if="store.languageSwitcher == 0">
              <h3>Environnement</h3>
              <p>
                L'Office s'engage dans une démarche éco-responsable à travers
                son programme de sobriété. Nous nous efforçons de transformer
                nos habitudes de consommation face au défi de la transition
                énergétique.
              </p>
            </span>
            <span v-if="store.languageSwitcher == 1">
              <h3>Environment</h3>
              <p>
                The Notarial Office is committed to an eco-responsible approach
                through a sobriety plan. We are committed to changing and
                bettering our daily habits to reduce our consumption of water,
                electricity and paper
              </p>
            </span>
            <span v-if="store.languageSwitcher == 2">
              <h3>Medio ambiente</h3>
              <p>
                La Notaría se compromete en un enfoque eco-responsable a través
                de su plan de sobriedad. Nos esforzamos cambiar nuestros hábitos
                cotidianos de consumo para contribuir a la contesta hacia
                desafíos contemporáneos de transición ecológica.
              </p>
            </span>
          </div>
        </div>
      </Slide>

      <template #addons>
        <Navigation />
      </template>
    </Carousel>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, onBeforeMount } from "vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import { useLanguage } from "@/config/stores";
const store = useLanguage();
let sWidth = ref();

onBeforeMount(async () => {
  sWidth.value = document.documentElement.clientWidth;
});

onMounted(async () => {
  window.addEventListener("resize", evtHandler, { passive: true });
});

onUnmounted(async () => {
  window.removeEventListener("resize", evtHandler, { passive: true });
});

function evtHandler() {
  sWidth.value = document.documentElement.clientWidth;
}
</script>

<style></style>
