<template>
  <div class="container__etude">
    <h1>Ducamp-Monod<br />& Associés</h1>

    <div
      id="oPic"
      class="container__etude__img fader fade--right"
      data-fade-speed="600"
    >
      <img
        :src="require('@/assets/roof.webp')"
        alt="Saint-Augustin"
        height="440"
        loading="lazy"
      />
    </div>

    <div>
      <span v-if="store.languageSwitcher == 0">
        <p>
          L’Office Notarial Ducamp-Monod & Associés, fondé en 1791, est une
          figure historique du notariat parisien. Il a hérité de la mémoire et
          contribué à faire vivre le savoir-faire traditionnel généraliste de sa
          profession. Il s’est toujours attaché, au fil des siècles, à écrire
          l’histoire du droit notarial de la plume à la tablette, en conjuguant
          les évolutions de fond et les progrès technologiques pour toujours
          faire face aux réalités sociétales, aux contraintes législatives et
          aux enjeux environnementaux.
        </p>

        <p>
          Indépendamment de ses activités notariales traditionnelles, l'Étude a
          aujourd'hui notamment développé un savoir-faire et une expertise
          reconnus dans le domaine du droit international privé.
        </p>

        <p>
          Les prestations peuvent être rendues indifféremment en langues
          française, anglaise ou espagnole sur simple demande.
        </p>
      </span>

      <span v-if="store.languageSwitcher == 1">
        <p>
          Ducamp-Monod & Associés Notary Office, founded in 1791, is a historic
          figure in the Paris notarial profession. Ducamp-Monod & Associés has
          inherited the notary's memory and contributed to keeping alive the
          profession's traditional generalist know-how. Over the centuries, it
          has always endeavored to write the history of notarial law from pen to
          tablet, combining fundamental developments and technological progress
          to keep pace with societal realities, legislative constraints and
          environmental challenges.
        </p>

        <p>
          In addition to its traditional notarial activities, the Law Firm has
          developed a recognized expertise in the field of private international
          law.
        </p>

        <p>
          The Law Firm services can be rendered in French, English or Spanish
          upon simple request.
        </p>
      </span>

      <span v-if="store.languageSwitcher == 2">
        <p>
          La Notaría Ducamp-Monod & Associés, fundada en 1791, es una figura
          histórica del notariado parisino. Ha heredado la memoria y contribuido
          a mantener vivo el saber hacer generalista tradicional de su
          profesión. A lo largo de los siglos, siempre se ha esforzado por
          escribir la historia del derecho notarial de la pluma a la tableta,
          combinando los desarrollos fundamentales y los avances tecnológicos
          para seguir el ritmo de las realidades de la sociedad, las
          limitaciones legislativas y las cuestiones medioambientales.
        </p>

        <p>
          Independientemente de sus actividades notariales tradicionales, la
          notaría desarrolló en particular un conocimiento y una competencia
          reconocidos en el ámbito del derecho internacional privado.
        </p>

        <p>
          Los servicios pueden ser prestados indistintamente en lengua francesa,
          inglesa o española, a petición del solicitante.
        </p>
      </span>
    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeUnmount, onMounted } from "vue";
//Language
import { useLanguage } from "@/config/stores";
const store = useLanguage();
const [elem, cName] = [ref(), ref("faded")];

onMounted(async () => {
  elem.value = document.getElementById("oPic");
  window.addEventListener("scroll", tEvent, { passive: true });
});

onBeforeUnmount(async () => {
  window.removeEventListener("scroll", tEvent, { passive: true });
});

function tEvent() {
  elem.value.getBoundingClientRect().top <= 800 &&
  !elem.value.classList.contains(cName.value)
    ? elem.value.classList.toggle(cName.value)
    : elem.value.getBoundingClientRect().top > 800 &&
      elem.value.classList.contains(cName.value)
    ? elem.value.classList.toggle(cName.value)
    : null;
}
</script>

<style></style>
