<template>
  <div>
    <div class="container__labels">
      <h2>
        {{
          store.languageSwitcher == 0
            ? "Nos labels"
            : store.languageSwitcher == 1
            ? "Our labels"
            : "Nuestras etiquetas"
        }}
      </h2>
      <div
        class="container__labels--holder"
        v-for="(label, i) in labels"
        :key="i"
      >
        <img
          width="130"
          height="130"
          :id="`label_${i}`"
          class="fader fade--bottom"
          :src="label.src"
          loading="lazy"
          :alt="
            store.languageSwitcher == 0 ? label.fr : store.languageSwitcher == 1 ? label.en : label.sp
          "
          :title="
            store.languageSwitcher == 0 ? label.fr : store.languageSwitcher == 1 ? label.en : label.sp
          "
          :data-fade-speed="i == 0 ? 300 : i == 1 ? 600 : 900"
        />

        <h3>
          {{
            store.languageSwitcher == 0 ? label.fr : store.languageSwitcher == 1 ? label.en : label.sp
          }}
        </h3>

        <p>
          {{
            store.languageSwitcher == 0
              ? label.text_fr
              : store.languageSwitcher == 1
              ? label.text_en
              : label.text_sp
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeUnmount, onMounted } from "vue";
// Labels
import { labels } from "@/config/team.js";
//Language
import { useLanguage } from "@/config/stores";
const store = useLanguage();
const [elem, cName] = [ref([]), ref("faded")];

onMounted(async () => {
  for (let i = 0; i < labels.length; i++) {
    elem.value.push(document.getElementById(`label_${i}`));
  }
  window.addEventListener("scroll", tEvent, { passive: true });
});

onBeforeUnmount(async () => {
  window.removeEventListener("scroll", tEvent, { passive: true });
});

function tEvent() {
  elem.value.forEach((elem) => {
    elem.getBoundingClientRect().top <= 700 &&
    !elem.classList.contains(cName.value)
      ? elem.classList.toggle(cName.value)
      : elem.getBoundingClientRect().top > 700 &&
        elem.classList.contains(cName.value)
      ? elem.classList.toggle(cName.value)
      : null;
  });
}
</script>

<style></style>
