<template>
  <Feedback
    v-if="mFeedback != null"
    @untoggle="untoggle"
    :toggle="mFeedback != null ? mFeedback : null"
  />

  <div class="container__contact--form--holder">
    <!--  see https://formsubmit.co/help for more info / deactivate mail -->
    <form action="mailto:dma@paris.notaires.fr" enctype="text/plain">
      <input
        type="text"
        name="subject"
        id="subject"
        for="Subject"
        v-model="contact.subject"
        @input="subjectValidator"
        required
        :placeholder="
          store.languageSwitcher == 0
            ? 'Sujet du message'
            : store.languageSwitcher == 1
            ? 'Mail subject'
            : 'Asunto del email'
        "
      />

      <p id="subjectValidator" class="input--error--msg">
        {{
          store.languageSwitcher == 0
            ? "Autorisation limitée aux lettres"
            : store.languageSwitcher == 1
            ? "Authorisation limited to letters"
            : "Autorización limitada a las cartas"
        }}
      </p>

      <!-- <input
        type="text"
        id="Nom"
        name="body"
        v-model="contact.surname"
        @input="surnameValidator"
        required
        :placeholder="
          store.languageSwitcher == 0
            ? 'Votre nom'
            : store.languageSwitcher == 1
            ? 'Your surname'
            : 'Su nombre'
        "
      />

      <p id="surnameValidator" class="input--error--msg">
        {{
          store.languageSwitcher == 0
            ? "Autorisation limitée aux lettres"
            : store.languageSwitcher == 1
            ? "Authorisation limited to letters"
            : "Autorización limitada a las cartas"
        }}
      </p>

      <input
        type="text"
        id="Telephone"
        name="body"
        v-model="contact.phone"
        @input="phoneValidator"
        required
        :placeholder="
          store.languageSwitcher == 0
            ? 'Votre téléphone'
            : store.languageSwitcher == 1
            ? 'Phone number'
            : 'Número de teléfono'
        "
      /> -->
      <!-- 
      <p id="phoneValidator" class="input--error--msg">
        {{
          store.languageSwitcher == 0
            ? "Autorisation limitée aux chiffres"
            : store.languageSwitcher == 1
            ? "Authorisation limited to numbers"
            : "Autorización limitada a números"
        }}
      </p> -->

      <textarea
        id="body"
        name="body"
        v-model="contact.message"
        @input="messageValidator"
        required
        :placeholder="
          store.languageSwitcher == 0
            ? 'Ecrivez nous un message qui résume votre besoin'
            : store.languageSwitcher == 1
            ? 'Write us a message summarizing your need'
            : 'Escríbanos un mensaje resumiendo sus necesidades'
        "
      ></textarea>

      <p id="messageValidator" class="input--error--msg">
        {{
          store.languageSwitcher == 0
            ? "Attention, caractères spéciaux détecter"
            : store.languageSwitcher == 1
            ? "Beware, special characters detected"
            : "Advertencia, caracteres especiales detectados"
        }}
      </p>

      <!-- <input
        type="hidden"
        name="_next"
        value="http://localhost:8080/mail-recu"
      /> -->

      <!-- :disabled="!contact.message || !contact.subject || !contact.phone || !contact.email || !contact.surname && !isInputError.value" -->
      <!-- :disabled="!isSending.value" -->
      <button
        type="submit"
        class="contact--form__form__button form__button"
        @click="mailIsSent"
      >
        {{
          store.languageSwitcher == 0
            ? "Obtenir un rendez-vous"
            : store.languageSwitcher == 1
            ? "Get an appointment"
            : "Obtener una cita"
        }}
      </button>
    </form>
  </div>
</template>

<script setup>
import { ref, onBeforeUpdate } from "vue";
//import { API, axios } from "@/config/config.js";
import Feedback from "@/components/MailFeedback.vue";
import { useLanguage } from "@/config/stores";
const store = useLanguage();

const [mFeedback, isSending, isInputError] = [
  ref(null),
  ref(false),
  ref(false),
];

const [isSubjectError, isMessageError] = [ref(false), ref(false)];

const contact = ref({
  form: "dma notaires - prise de contact",
  subject: "",
  message: "",
});

onBeforeUpdate(() => {
  if (isSubjectError.value || isMessageError.value) {
    isInputError.value = true;
  } else {
    isInputError.value = false;
  }

  if (contact.value.subject && contact.value.message && !isInputError.value) {
    isSending.value = true;
  } else {
    isSending.value = false;
  }
});

function mailIsSent() {
  setTimeout(() => {
    // contact.value.surname =
    //   contact.value.email =
    //   contact.value.phone =
    contact.value.subject = contact.value.message = "";
  }, "10000");
}

// function surnameValidator() {
//   if (!/^[\p{L} ]+$/u.test(contact.value.surname)) {
//     document.getElementById("surnameValidator").style.opacity = 1;
//     isNameError.value = true;
//   } else {
//     document.getElementById("surnameValidator").style.opacity = 0;
//     isNameError.value = false;
//   }
// }

// function phoneValidator() {
//   if (!/^[\d\s+]+$/.test(contact.value.phone)) {
//     document.getElementById("phoneValidator").style.opacity = 1;
//     isPhoneError.value = !isPhoneError.value;
//   } else {
//     document.getElementById("phoneValidator").style.opacity = 0;
//     isPhoneError.value = !isPhoneError.value;
//   }
// }

function subjectValidator() {
  if (!/^[\p{L} ]+$/u.test(contact.value.subject)) {
    document.getElementById("subjectValidator").style.opacity = 1;
    isSubjectError.value = true;
  } else {
    document.getElementById("subjectValidator").style.opacity = 0;
    isSubjectError.value = false;
  }
}

function messageValidator() {
  if (!/^[\wÀ-ÿ\s.,!?;'"(){}[\]\-/\\]+$/.test(contact.value.message)) {
    document.getElementById("messageValidator").style.opacity = 1;
    isMessageError.value = true;
  } else {
    document.getElementById("messageValidator").style.opacity = 0;
    isMessageError.value = false;
  }
}

// @click="sendMail"

//

// async function sendMail() {
//   if (
//     !contact.value.surname ||
//     !contact.value.email ||
//     !contact.value.phone ||
//     !contact.value.subject ||
//     !contact.value.message ||
//     !validateEmail(contact.value.email)
//   ) {
//     mFeedback.value = false;
//   } else {
//     isSending.value = true;
//     const json = JSON.stringify(contact.value);

//     await axios
//       .post(API, json, {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       })
//       .then(async (res) => {
//         if (res.status === 200) {
//           contact.value.surname =
//             contact.value.email =
//             contact.value.phone =
//             contact.value.subject =
//             contact.value.message =
//               "";
//           mFeedback.value = true;
//         }
//         if (res.status !== 200) {
//           mFeedback.value = false;
//         }
//         isSending.value = false;
//       })
//       .catch((e) => {
//         if (e) {
//           console.error("error" + e);
//           mFeedback.value = false;
//           isSending.value = false;
//         }
//       });
//   }
// }

// function validateEmail(email) {
//   var re = /\S+@\S+\.\S+/;
//   return re.test(email);
// }

function untoggle() {
  mFeedback.value = null;
}
</script>

<style></style>
